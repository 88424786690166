// src/components/AboutUs.js
import React from 'react';

function AboutUs() {
  return (
    <div>
      <h2>About Us</h2>
      <p>Learn more about our company and team.</p>
    </div>
  );
}

export default AboutUs;
