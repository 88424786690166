// src/components/Services.js
import React from 'react';

function Services() {
  return (
    <div>
      <h2>Our Services</h2>
      <ul>
        <li>Service 1</li>
        <li>Service 2</li>
        <li>Service 3</li>
      </ul>
    </div>
  );
}

export default Services;
