// src/components/Home.js
import React from 'react';

function Home() {
  return (
    <div>
      <h2>Welcome to My Website</h2>
      <p>This is the home page of my professional website.</p>
    </div>
  );
}

export default Home;
